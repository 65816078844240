
import { defineComponent } from "vue";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import store from "@/store";
import router from "@/router/router";

export default defineComponent({
  name: "profile",
  components: {

  },

  setup(){
    const isUserAuthenticated = store.getters.isUserAuthenticated
    if(isUserAuthenticated == false) {
      router.push({ name: "dashboard" });
    }
  },

  data () {
    return{
      site_url: process.env.VUE_APP_BACKEND_URL,
    }
  },
  methods: {
    hasBonus(bonus){
      if(bonus) {
        return true
      }
    }
  },
  computed: {
    user() {
      return store.getters.getUserInfoAll;
    }
  },
});
